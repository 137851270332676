import { QueryRowFormat } from "@itwin/core-common";
import { IModelConnection, ScreenViewport } from "@itwin/core-frontend";
import { SmartEquipment } from "../types";


export class SmartEquipmentData {
    public static iModel: IModelConnection;
    public static equipments: SmartEquipment[] = [];
  
    /** Initialize the iModel based on viewport */
    public static initializeLink = async (vp: ScreenViewport) => {
        SmartEquipmentData.iModel = vp.iModel;
    };
  
    /** Fetch the Pipe Runs list based on ECSql query */
    public static fetchEquipment = async (): Promise<any> => {
      let equipments: SmartEquipment[] = SmartEquipmentData.equipments;
      try {
        //const query = `SELECT smart3D.DWGNO AS PipeRun, json_group_array ( json_object ( 'id', printf('0x%x', nwd.ECInstanceId), 'class', ec_classname(nwd.ECClassId) ) ) AS Elements, smart3D.DLWebLink as ISOLink, smart3d.DLWebLink_PID as PIDLink FROM NwdDynamic.Smart3D smart3D JOIN NwdDynamic.NwdComponent nwd ON smart3D.Element.id = nwd.ECInstanceId WHERE smart3d.DWGNO != 'NULL' GROUP BY smart3d.DWGNO ORDER BY smart3d.DWGNO`;
        // const query = `SELECT e.*,smartEquipment.Name,smartEquipment.Origin FROM bis.SpatialIndex e JOIN (select parent,Name,Origin,min(child) as child from(
        //     select parent_table.ECInstanceId parent, child_table.ECInstanceId child,smart3d.Name,child_table.Origin
        //      from NwdDynamic.Smart3D smart3d JOIN NwdDynamic.NwdComponent parent_table
        //       ON smart3d.Element.id = parent_table.ECInstanceId 
        //       JOIN NwdDynamic.NwdComponent child_table 
        //       ON parent_table.ECInstanceId = child_table.Parent.id 
        //       WHERE smart3d.ClassName_RN = 'CPSmartEquipment' 
        //       and child_table.Origin is not null) parent_child_pairs group by parent,Name)
        //        smartEquipment ON e.ECInstanceId = smartEquipment.child`;

        /**Added query 1 for to get all smart equipment*/
        //const query1=`select e.*,smartEquipment.name as Name, smartEquipment.ori as Origin FROM bis.SpatialIndex e JOIN (select parents,name,ori,min(childs) as childs from (SELECT comp.ECInstanceId parents, child_table.ECInstanceId childs, child.userlabel name, child_table.Origin ori FROM nwddynamic.nwdcomponent comp JOIN nwddynamic.NwdComponentOwnsChildComponents rel ON rel.sourceecinstanceid = comp.ecinstanceid JOIN NwdDynamic.NwdComponent child_table ON child.ECInstanceId = child_table.Parent.id JOIN nwddynamic.nwdcomponent child ON child.ecinstanceid = rel.targetecinstanceid WHERE (comp.UserLabel LIKE '%Equipment Major%' OR comp.UserLabel LIKE '%Pipe%') and child_table.Origin is not NULL) parent_child_pairs group by parents,name) smartEquipment ON e.ECInstanceId = smartEquipment.childs`;
        const query1=`SELECT e.*,smartEquipment.Name,smartEquipment.Origin FROM bis.SpatialIndex e JOIN (select parent,Name,Origin,min(child) as child from( select parent_table.ECInstanceId parent, child_table.ECInstanceId child,smart3d.Name,child_table.Origin from NwdDynamic.Smart3D smart3d JOIN NwdDynamic.NwdComponent parent_table ON smart3d.Element.id = parent_table.ECInstanceId JOIN NwdDynamic.NwdComponent child_table ON parent_table.ECInstanceId = child_table.Parent.id WHERE smart3d.ClassName_RN = 'CPSmartEquipment' AND smart3d.EquipmentClassification0 IS NULL and child_table.Origin is not null) parent_child_pairs group by parent,Name) smartEquipment ON e.ECInstanceId = smartEquipment.child`;
        const results1 = SmartEquipmentData.iModel.query(query1, undefined, { rowFormat: QueryRowFormat.UseJsPropertyNames });
        const values1 = [];
        for await (const row of results1) {
          values1.push(row);
        }
        /**Added query 2 for to remove equipment minor and electrical components*/
        //const query2=`SELECT e.*,smartEquipment.Name,smartEquipment.Origin FROM bis.SpatialIndex e JOIN (select parent,Name,Origin,min(child) as child from( select parent_table.ECInstanceId parent, child_table.ECInstanceId child,smart3d.Name,child_table.Origin from NwdDynamic.Smart3D smart3d JOIN NwdDynamic.NwdComponent parent_table ON smart3d.Element.id = parent_table.ECInstanceId JOIN NwdDynamic.NwdComponent child_table ON parent_table.ECInstanceId = child_table.Parent.id WHERE (smart3d.ClassName_RN = 'CPSmartEquipment' AND smart3d.EquipmentClassification0 = 'Instrument Equipment') and child_table.Origin is not null) parent_child_pairs group by parent,Name) smartEquipment ON e.ECInstanceId = smartEquipment.child`;
        //const query2=`select smartEquipment.name FROM bis.SpatialIndex e JOIN (select parents,name,ori,min(childs) as childs from (SELECT comp.ECInstanceId AS parents, child_table.ECInstanceId AS childs, child.userlabel AS name, child_table.Origin AS ori FROM nwddynamic.nwdcomponent comp JOIN nwddynamic.NwdComponentOwnsChildComponents rel ON rel.sourceecinstanceid = comp.ecinstanceid JOIN NwdDynamic.NwdComponent child_table ON child.ECInstanceId = child_table.Parent.id JOIN nwddynamic.nwdcomponent child ON child.ecinstanceid = rel.targetecinstanceid WHERE (comp.UserLabel LIKE '%Equipment Minor%' OR comp.UserLabel LIKE '%Equipment Temp Steel%') AND child_table.Origin IS NOT NULL) parent_child_pairs group by parents,name) smartEquipment ON e.ECInstanceId = smartEquipment.childs`;
        const query2=`select smartEquipment.name FROM bis.SpatialIndex e JOIN (select parents,name,ori,min(childs) as childs from (SELECT comp.ECInstanceId AS parents, child_table.ECInstanceId AS childs, child.userlabel AS name, child_table.Origin AS ori FROM nwddynamic.nwdcomponent comp JOIN nwddynamic.NwdComponentOwnsChildComponents rel ON rel.sourceecinstanceid = comp.ecinstanceid JOIN NwdDynamic.NwdComponent child_table ON child.ECInstanceId = child_table.Parent.id JOIN nwddynamic.nwdcomponent child ON child.ecinstanceid = rel.targetecinstanceid WHERE (comp.UserLabel LIKE '%Equipment Minor%' OR comp.UserLabel LIKE '%Equipment Temp Steel%' OR comp.UserLabel LIKE '%0500-Electrical%' OR comp.UserLabel LIKE '%TEMP ELEC%' OR comp.UserLabel LIKE '%Lighting%') AND child_table.Origin IS NOT NULL) parent_child_pairs group by parents,name) smartEquipment ON e.ECInstanceId = smartEquipment.childs`;
        const results2 = SmartEquipmentData.iModel.query(query2, undefined, { rowFormat: QueryRowFormat.UseJsPropertyNames });
        const values2 = [];
        for await (const row of results2) {
          values2.push(row);
        }
        console.log('query2 values are',values2);
        
        // Extract names from values2 for fast lookup
        const namesInValues2 = new Set(values2.map(item => item.name));
        
        
        // Filter values1 to exclude names present in values2
        const filteredValues1 = values1.filter(item => !namesInValues2.has(item.name))
        // Output the filtered values
        console.log('Filtered values1:', filteredValues1);


        equipments = filteredValues1;  
      } catch (error) {
        console.log(error);
      }
      
      return equipments;
    };
  }